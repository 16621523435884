import React from "react";
import ob1mob from "../../resources/images/overlays/ob1-mob.svg";
import ob1tab from "../../resources/images/overlays/ob1-tab.svg";
import ot1mob from "../../resources/images/overlays/ot1-mob.svg";
import ot1tab from "../../resources/images/overlays/ot1-tab.svg";
import ot1 from "../../resources/images/overlays/o1-top.svg";
import ob1 from "../../resources/images/overlays/o1-bot.svg";
import useWindowSize from "../use-window-size";
import { responsiveGet } from "../utils";

const Overlay = ({ children, waveTop, waveBot, overflowHidden }) => {
  const { width } = useWindowSize();
  return (
    <div
      className={`absolute w-full h-full top-0 left-0 ${
        overflowHidden ? "overflow-hidden " : ""
      }pointer-events-none z-10`}
    >
      {children}
      {waveTop && (
        <img
          src={responsiveGet(width, {
            mob: ot1mob,
            sm: ot1tab,
            md: ot1tab,
            def: ot1,
          })}
          alt=""
          className="absolute w-full -top-px left-0 z-10"
        />
      )}
      {waveBot && (
        <img
          src={responsiveGet(width, {
            mob: ob1mob,
            sm: ob1tab,
            md: ob1tab,
            def: ob1,
          })}
          alt=""
          className="absolute w-full -bottom-px left-0 z-10"
        />
      )}
    </div>
  );
};

Overlay.defaultProps = {
  waveTop: false,
  waveBot: false,
  overflowHidden: true,
};

export default Overlay;
