import React, { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import { StoreContext } from "../../context/store-context";
import placeholder from "../../resources/images/placeholder.png";
import { getTranslatedIfAvail } from "../utils";

const LineitemCard = ({ li }) => {
  const { src, altText } = li?.variant?.image;
  const variant = li.variant.title.replace("Default Title", "");
  const price = li.variant.price;

  const storeCtx = useContext(StoreContext);
  const qt = li.quantity;
  const minus1 = () => {
    if (!storeCtx.loading) {
      storeCtx.updateLineItem(storeCtx.checkout.id, li.id, qt - 1);
    }
  };
  const plus1 = () => {
    if (!storeCtx.loading) {
      storeCtx.updateLineItem(storeCtx.checkout.id, li.id, qt + 1);
    }
  };
  const remove = () => {
    if (!storeCtx.loading) {
      storeCtx.removeLineItem(storeCtx.checkout.id, li.id);
    }
  };

  const { t, locale } = useContext(LanguageContext);
  const l = locale;

  console.log(li);

  // ENG PRODS STORE
  const { translatedProds } = storeCtx;
  const getT = (sku) =>
    translatedProds[l] && translatedProds[l][sku.toLowerCase()]?.title;

  const tit = getTranslatedIfAvail(
    li.title,
    getT(li.variant.product.handle),
    true
  );
  const sot = getTranslatedIfAvail(
    li.title,
    getT(li.variant.product.handle),
    false
  );

  return (
    <article className="flex gap-5 items-center">
      <img
        src={src || placeholder}
        alt={altText ?? ""}
        width={95}
        height={95}
        className="object-cover"
      />
      <div className="flex-grow">
        <h3 className="font-bold">{tit}</h3>
        <p className="text-xs mt-1">{sot}</p>
        <p className="text-xs mt-1">{variant}</p>
        <p className="mt-2">€ {price}</p>
      </div>
      <div className="flex-shrink-0">
        <div className="rounded-full border border-ci-gray-light flex pt-0.5 pb-1 px-4 gap-3 font-bold">
          <button
            className="disabled:opacity-70"
            onClick={minus1}
            disabled={storeCtx.loading}
          >
            -
          </button>
          {qt}
          <button
            className="disabled:opacity-70"
            onClick={plus1}
            disabled={storeCtx.loading}
          >
            +
          </button>
        </div>
        <div className="text-center">
          <button
            onClick={remove}
            className="mt-2.5 text-xs disabled:opacity-70"
            disabled={storeCtx.loading}
          >
            {t.rimuovi}
          </button>
        </div>
      </div>
    </article>
  );
};

export default LineitemCard;
