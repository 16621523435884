import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import useWindowSize from "./use-window-size";

const Button = ({ children, className, type }) => {
  let col,
    hov,
    borderClass = "",
    textCol = "text-white";
  switch (type) {
    case "red":
      col = "#d16e57";
      hov = "#bc4833";
      break;
    case "green":
      col = "#aabbbb";
      hov = "#91a5a5";
      break;
    case "red-outline":
      col = "#ffffff";
      hov = "#f0d1ca";
      borderClass = " border border-ci-red";
      textCol = "text-ci-red";
      break;
    case "green-outline":
      col = "#ffffff";
      hov = "#e3e9e9";
      borderClass = " border border-ci-green";
      textCol = "text-ci-green";
      break;
    default:
      col = "#d16e57";
      hov = "#bc4833";
  }

  return (
    <div
      className={`rounded-full font-bold relative liq-parent overflow-hidden ${textCol}${borderClass}`}
    >
      <div className={`relative z-10 ${className}`}>{children}</div>
      <div
        className="liquid"
        style={{
          backgroundColor: hov,
          "--liq1": col + "80",
          "--liq2": col,
        }}
      />
    </div>
  );
};

Button.defaultProps = {
  className: "px-10 py-5",
  type: "red-outline",
};

export default Button;
