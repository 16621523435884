import { graphql, useStaticQuery } from "gatsby";
import { parseStory } from "./utils";

const useBasePagesSEO = () => {
  const res = useStaticQuery(graphql`
    query BasePagesSEO {
      seo: allStoryblokEntry(filter: { slug: { eq: "pagine-base-seo" } }) {
        edges {
          node {
            content
            slug
            lang
          }
        }
      }
    }
  `);

  return res.seo.edges.reduce(
    ({ pageInfo, urls2pag }, { node }) => {
      const story = parseStory(node);
      const lang = story.lang === "default" ? "it" : story.lang;
      const pages = story.content.pagine;

      pages.forEach((p) => {
        !(p.pagina in pageInfo) && (pageInfo[p.pagina] = {});
        pageInfo[p.pagina][lang] = p;
        urls2pag[p.url] = p.pagina;
      });

      return { pageInfo, urls2pag };
    },
    { pageInfo: {}, urls2pag: {} }
  );
};

export default useBasePagesSEO;
