import React from "react";
import star from "../../resources/images/icon_star.svg";
import halfStar from "../../resources/images/icon_half_star_outline.svg";
import starOutline from "../../resources/images/icon_star_outline.svg";

const Stars = ({ rating }) => {
  const safeRating = Math.min(10, Math.max(0, rating));
  const [fs, hs, es] =
    safeRating % 2 === 0
      ? [safeRating / 2, 0, 5 - safeRating / 2]
      : [(safeRating - 1) / 2, 1, 5 - (safeRating + 1) / 2];

  return (
    <div className="flex">
      {[...Array(fs).keys()].map((_, i) => (
        <img key={i} src={star} alt="" />
      ))}
      {hs === 1 && <img src={halfStar} alt="" />}
      {[...Array(es).keys()].map((_, i) => (
        <img key={i} src={starOutline} alt="" />
      ))}
      <p className="sr-only">Valutazione {safeRating} su 10</p>
    </div>
  );
};

export default Stars;
