import React, { useState, useContext } from "react";
import { StoreContext } from "../../context/store-context";
import placeholder from "../../resources/images/placeholder.png";
import Button from "../button";

const Compensa = () => {
  const { isCompensa, setCompensa, loading } = useContext(StoreContext);
  const [isRegalo, setIsRegalo] = useState(false);
  const [hasMessaggio, setHasMessaggio] = useState(false);
  const [messaggio, setMessaggio] = useState("");
  return (
    <div>
      <div className="flex gap-5 items-end">
        <img src={placeholder} alt="" width={95} />
        <div style={{ maxWidth: "220px" }} className="mr-10">
          <h3 className="ci-h3">Diamoci una mano!</h3>
          <p className="mt-4 text-sm">
            Lega la tua scultura ad un albero piantato a tuo nome.
          </p>
        </div>
      </div>
      <div className="mt-5 text-xs">
        Ci siamo uniti a <b>One Tree Planted</b> per piantare alberi in tutto il
        mondo e darti l’opportunità di fare lo stesso.
        <br />
        <br /> ll nostro pianeta è una risorsa condivisa. Tutti abbiamo ricevuto
        molto ed è ora di restituire qualcosa.
        <br />
        <br />
        L’importo del contributo è calcolato in base al tipo ed alla quantità
        del tuo ordine, così che tu possa compensare le emissioni di carbonio e
        piantare più di quanto consumi.
      </div>
      {isCompensa ? (
        <button
          className="mt-6 w-full"
          onClick={() => {
            setCompensa(false);
          }}
        >
          <Button
            type="green-outline"
            className="p-4 text-center w-full font-bold"
          >
            Rimuovi il mio albero
          </Button>
        </button>
      ) : (
        <button
          className="mt-6 w-full"
          onClick={() => {
            setCompensa(true);
          }}
        >
          <Button type="green" className="p-4 text-center w-full font-bold">
            Sì! Voglio piantare 1 albero per 1,00€
          </Button>
        </button>
      )}
    </div>
  );
};

export default Compensa;
