import { Transition } from "@headlessui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { StoreContext } from "../../context/store-context";
import closeW from "../../resources/images/close-w.svg";
import close from "../../resources/images/close.svg";
import emptyCartIc from "../../resources/images/empty-cart.svg";
import regalaIc from "../../resources/images/icon_pacco_regalo@1x.svg";
import compensaIc from "../../resources/images/icon_compensa@1x.svg";
import couponIc from "../../resources/images/icon_coupon@1x.svg";
import SameDay from "../cart/same-day";
import LineitemCard from "../cart/lineitem-card";
import { Player } from "@lottiefiles/react-lottie-player";
import animHeart from "../../resources/images/heart.json";
import Coupon from "../cart/coupon";
import Regala from "../cart/regala";
import ondaw from "../../resources/images/onda-cart-w.svg";
import ondab from "../../resources/images/onda-cart-b.svg";
import Overlay from "../commons/overlay";
import Compensa from "../cart/compensa";
import { filterPromo } from "../utils";
import { LanguageContext } from "../../context/language-context";
import { formatPrice } from "../utils";
import Button from "../button";

const limiteSpedizione = 80;

const locales = {
  en: "en_UK",
};

const Cart = () => {
  const storeCtx = useContext(StoreContext);

  const lineItems = (storeCtx?.checkout?.lineItems ?? []).filter(filterPromo);
  const totalPrice = parseFloat(storeCtx?.checkout?.totalPrice) ?? 0;
  const numberOfProducts = lineItems.reduce((tot, li) => li.quantity + tot, 0);
  const hasFreeDelivery = totalPrice >= limiteSpedizione;
  const hasDiscount = false;

  const heartRef = useRef();

  const [activeSubmenu, setActiveSubmenu] = useState("");

  const { t, locale } = useContext(LanguageContext);
  const l = locale;

  const webUrl =
    l === "it"
      ? storeCtx?.checkout?.webUrl ?? ""
      : storeCtx?.checkout?.webUrl +
        "&locale=" +
        (l in locales ? locales[l] : l);

  return (
    <>
      <Transition
        show={storeCtx.isCartOpen}
        enter="transition duration-300 delay-150"
        enterFrom="opacity-0"
        enterTo="opacity-50"
        entered="opacity-50"
        leave="transition duration-150"
        leaveFrom="opacity-50"
        leaveTo="opacity-0"
        as="div"
        className="fixed w-full h-full bg-black top-0 z-50"
      />
      <Transition
        show={storeCtx.isCartOpen}
        enter="transition-all ease-out duration-300"
        enterFrom="opacity-0 -right-full"
        enterTo="opacity-100 right-0"
        entered="right-0"
        leave="transition-all ease-in duration-300"
        leaveFrom="opacity-100 right-0"
        leaveTo="opacity-0 -right-full"
        className="w-full z-50 fixed bg-white h-full top-0"
        style={{ maxWidth: "450px" }}
        afterEnter={() => {
          heartRef.current && heartRef.current.play();
        }}
      >
        <aside className="flex flex-col h-full">
          <header className="bg-ci-green relative text-white text-center pt-5 pb-10 px-2">
            <button
              className="absolute top-4 right-4"
              onClick={() => {
                storeCtx.setCart(false);
              }}
            >
              <img src={closeW} alt="Chiudi il carrello" />
            </button>
            <h1 className="ci-h3">{t["tuo-carrello"]}</h1>
            <SameDay />
            <Overlay>
              <img
                src={ondab}
                alt=""
                className="absolute min-w-full left-0 -bottom-px"
              />
            </Overlay>
          </header>
          <div className="bg-ci-beige-light relative pt-4 pb-6 text-center">
            {!hasFreeDelivery ? (
              <p className="text-sm uppercase">
                {t["sped-1"]}{" "}
                <span className="text-ci-red">
                  € {formatPrice(limiteSpedizione - totalPrice, l)}
                </span>
                <br />
                {t["sped-2"]} <span className="font-bold">{t.gratuita}</span>{" "}
                {t["sped-3"]}
              </p>
            ) : (
              <p></p>
            )}
          </div>
          <div className="pt-6 flex-grow relative overflow-y-auto">
            <Overlay>
              <img
                src={ondaw}
                alt=""
                className="absolute min-w-full left-0 -top-px"
              />
            </Overlay>
            {numberOfProducts === 0 ? (
              <div className="pt-14 pb-10 flex flex-col gap-6 items-center">
                <Player
                  autoplay={true}
                  loop={false}
                  controls={false}
                  className="w-full"
                  src={animHeart}
                  ref={heartRef}
                  keepLastFrame={true}
                />
                <p className="font-bold text-ci-red">{t["carrello-vuoto"]}</p>
              </div>
            ) : (
              <div>
                <div className="p-7 flex flex-col gap-4">
                  {lineItems.map((li) => (
                    <LineitemCard li={li} key={li.id} />
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* acquista section */}
          <div className="relative flex-shrink-0">
            {/* popups */}
            <Submenu
              isActive={activeSubmenu === "regala"}
              setActiveSubmenu={setActiveSubmenu}
            >
              <Regala />
            </Submenu>
            <Submenu
              isActive={activeSubmenu === "compensa"}
              setActiveSubmenu={setActiveSubmenu}
            >
              <Compensa />
            </Submenu>
            <Submenu
              isActive={activeSubmenu === "coupon"}
              setActiveSubmenu={setActiveSubmenu}
            >
              <Coupon />
            </Submenu>
            {/* menus */}
            {/* <nav className="border-t border-b border-ci-beige-50 py-3 flex">
              <button
                className="border-ci-beige-50 border-r flex-1 flex flex-col items-center gap-1"
                onClick={() => {
                  setActiveSubmenu("regala");
                }}
              >
                <img src={regalaIc} alt="" />
                <p className="text-sm">{t.regala}</p>
              </button>
              <button
                className="border-ci-beige-50 border-r flex-1 flex flex-col items-center gap-1"
                onClick={() => {
                  setActiveSubmenu("compensa");
                }}
              >
                <img src={compensaIc} alt="" />
                <p className="text-sm">{t.compensa}</p>
              </button>
              <button
                className="flex-1 flex flex-col items-center gap-1"
                onClick={() => {
                  setActiveSubmenu("coupon");
                }}
              >
                <img src={couponIc} alt="" />
                <p className="text-sm">{t.coupon}</p>
              </button>
            </nav> */}
            <div className="py-6 px-8">
              {hasDiscount && (
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <p className="font-bold">Sconto</p>
                    <p>Natale10</p>
                  </div>
                  <p className="font-bold">-€{formatPrice(12.9, l)}</p>
                </div>
              )}
              <div className="flex justify-between items-center mt-4">
                <p className="font-bold">{t.subtotale}</p>
                <p className="font-bold">€{formatPrice(totalPrice, l)}</p>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="flex items-center">{t.spedizione}</div>
                <p>
                  {hasFreeDelivery
                    ? t.gratis
                    : `${t.da} €${formatPrice(9.9, l)}`}
                </p>
              </div>
              <a href={webUrl} className="mt-4 inline-block w-full text-center">
                <Button type="red">
                  <span className="uppercase">{t.completa}</span>
                </Button>
              </a>
            </div>
          </div>
        </aside>
      </Transition>
    </>
  );
};

const Submenu = ({ isActive, setActiveSubmenu, children }) => (
  <>
    <Transition
      show={isActive}
      enter="transition-opacity ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-80"
      entered="opacity-80"
      leave="transition-opacity ease-in duration-300"
      leaveFrom="opacity-80"
      leaveTo="opacity-0"
      className="absolute bottom-full w-full h-screen left-0 bg-black z-10"
    />
    <Transition
      show={isActive}
      enter="transition-all transform ease-out duration-300"
      enterFrom="opacity-0 scale-y-75"
      enterTo="opacity-100 scale-y-100"
      leave="transition-all transform ease-in duration-300"
      leaveFrom="opacity-100 scale-y-100"
      leaveTo="opacity-0 scale-y-75"
      className="w-full z-10 absolute bg-white bottom-full left-0 origin-bottom"
    >
      <div className="shadow-ci-subm py-5 px-8">
        <div className="flex justify-end mb-3">
          <button
            onClick={() => {
              setActiveSubmenu("");
            }}
          >
            <img src={close} alt="Chiudi" />
          </button>
        </div>
        {children}
      </div>
    </Transition>
  </>
);

export default Cart;
