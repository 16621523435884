import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import useBasePagesSEO from "./use-base-pages-seo";
// UTIL TO GET THE INTERPOLATION LINE TO POSITION BACKGROUND
// m * pn + b = rn
export const getRectParams = (p1, p2, r1 = 1024, r2 = 1862) => {
  const m = (p2 - p1) / (r2 - r1);
  const b = p1 - m * r1;
  return [m, b];
};

export const parseStory = (story) => {
  let content = story?.content;
  if ("content" in story && typeof story.content === "string") {
    content = JSON.parse(story.content);
  }
  return {
    ...story,
    content,
  };
};

// TIME-RELATED utils
export const [lh, lm, ls] = [18, 0, 0];
export const hasPassedLimitTime = (d) => {
  return (
    d.getHours() > lh ||
    (d.getHours() === lh && d.getMinutes() > lm) ||
    (d.getHours() === lh && d.getMinutes() === lm && d.getSeconds() > ls)
  );
};
export const getTimeDiff = (now) => {
  let minus = 0,
    s = ls - now.getSeconds();
  if (s < 0) {
    s += 60;
    minus = 1;
  }
  minus = 0;
  let m = lm - now.getMinutes() - minus;
  if (m < 0) {
    m += 60;
    minus = 1;
  }
  const h = lh - now.getHours() - minus;
  return { h, m, s };
};

// SWITCHER BASED ON WINDOW WIDTH
export const responsiveGet = (w, obj) => {
  const def = "def" in obj ? obj.def : null;
  if (w < 640) {
    return obj?.mob ?? def;
  }
  if (w < 768) {
    return obj?.sm ?? def;
  }
  if (w < 1024) {
    return obj?.md ?? def;
  }
  if (w < 1280) {
    return obj?.lg ?? def;
  }
  if (w < 1536) {
    return obj?.xl ?? def;
  }
  return "2xl" in obj ? obj["2xl"] : def;
};

// we filter out promo product that should not appear in the line items
export const filterPromo = (li) =>
  !(
    li?.variant?.product?.handle === "compensa" ||
    li?.variant?.product?.handle === "regala"
  );

export const getSubmenuAttr = (submenu, prodotti, urls) => {
  let label, link, img, hov;
  if (submenu.component === "submenu_element") {
    label = submenu.label;
    link = submenu.link;
    img = (
      <img
        src={submenu.img.filename}
        alt={submenu.img.alt}
        className="h-full w-auto"
      />
    );
    hov = (
      <img
        src={submenu.hov.filename}
        alt={submenu.hov.alt}
        className="h-full w-auto"
      />
    );
  } else if (submenu.component === "submenu_sku") {
    const sku = submenu.sku.toLowerCase();
    const p = prodotti[sku];
    label = p.title;
    link = sku in urls ? `${urls[sku]}` : `#`;
    img = (
      <img
        src={p.images[0].src}
        alt={p.images[0].altText ?? ""}
        className="h-full w-auto"
      />
    );
    hov = (
      <img
        src={p.images[1]?.src ?? p.images[0].src}
        alt={p.images[1]?.altText ?? "h-full w-auto"}
        className=""
      />
    );
  }
  return { label, link, img, hov };
};

// hooks to retrieve urls from storyblok
export const useUrls = () => {
  const { pageInfo } = useBasePagesSEO();
  return Object.entries(pageInfo).reduce((obj, [k, v]) => {
    Object.entries(v).forEach(([lang, page]) => {
      lang in obj || (obj[lang] = {});
      obj[lang][k.toLowerCase()] = page.url;
    });
    return obj;
  }, {});

  /* const res = useStaticQuery(graphql`
    query allUrls {
      urls: allStoryblokEntry(filter: { slug: { eq: "urls" } }) {
        nodes {
          content
          lang
        }
      }
    }
  `);

  return res.urls.nodes.reduce((obj, story) => {
    const lang = story.lang === "default" ? "it" : story.lang;

    obj[lang] = JSON.parse(story.content).urls.reduce((urlObj, url) => {
      urlObj[url.sku.toLowerCase()] = url.url;
      return urlObj;
    }, {});

    return obj;
  }, {}); */
};

export const formatPrice = (p, l = "it") => {
  return l === "it" ? p.toFixed(2).replace(".", ",") : p.toFixed(2);
};

export const getTranslatedIfAvail = (tit, trans, isTit) => {
  let [t1, s1, t2] = (trans || tit).split(" - ");
  if (isTit) {
    return t2 ? t1 + " - " + t2 : t1;
  } else {
    return s1;
  }
};
