import React, { useContext, useState } from "react";
import { StoreContext } from "../../context/store-context";
import attIc from "../../resources/images/icon_attention@1x.svg";
import closeW from "../../resources/images/close-w.svg";
import Button from "../button";

const Coupon = () => {
  const storeCtx = useContext(StoreContext);
  const discounts = storeCtx?.checkout?.discountApplications ?? [];
  const hasCoupon = discounts.length > 0;
  const [coupon, setCoupon] = useState("");
  const [invalid, setInvalid] = useState(false);

  const tryCoupon = () => {
    coupon &&
      storeCtx.tryApplyDiscount(coupon, () => {
        setInvalid(true);
      });
  };

  const removeCoupon = () => {
    storeCtx.removeDiscount(() => {
      setInvalid(false);
    });
  };

  console.log(storeCtx);
  return (
    <div>
      <h2 className="ci-h3">
        {hasCoupon ? "Codice sconto" : "Aggiungi il codice sconto"}
      </h2>
      {hasCoupon ? (
        <div className="gap-4 flex items-center mt-5">
          {discounts.map((d) => (
            <div
              className="ci-badge flex items-center gap-2 text-sm bg-ci-green text-white"
              key={d.code}
            >
              {d.code}
              <button onClick={removeCoupon}>
                <img src={closeW} alt="remove coupon" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <>
          <input
            type="text"
            className="mt-5 rounded-full border border-ci-gray-light py-4 px-6 w-full"
            onChange={(e) => {
              setCoupon(e.target.value);
            }}
            value={coupon}
            placeholder="Codice sconto"
          />
          {invalid && (
            <div className="mt-5 bg-ci-beige-light flex justify-center px-1 items-center gap-4 py-4">
              <img src={attIc} width={24} alt="attenzione" />
              <p className="text-ci-red  font-bold">
                Il codice promozionale non è valido!
              </p>
            </div>
          )}
          <button
            className="mt-6 w-full"
            onClick={tryCoupon}
            disabled={coupon === "" || storeCtx.loading}
          >
            <Button
              type="green"
              className="p-4 text-center w-full font-bold disabled:cursor-not-allowed"
            >
              Applica codice
            </Button>
          </button>
        </>
      )}
    </div>
  );
};

export default Coupon;
