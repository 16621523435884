import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import burgerIc from "../../resources/images/burger.svg";
import closeIc from "../../resources/images/close.svg";
import rigthIc from "../../resources/images/icon_arrow_right.svg";
import downIc from "../../resources/images/icon_arrow_down.svg";
import { Link } from "gatsby";
import { getSubmenuAttr, getTranslatedIfAvail } from "../utils";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import { StoreContext } from "../../context/store-context";

const MenuMob = ({ menu, prodotti, urls }) => {
  const { t, locale } = useContext(LanguageContext);
  const l = locale;
  const { translatedProds } = useContext(StoreContext);
  return (
    <Menu as="div" className="lg:hidden flex items-center">
      {({ open }) => (
        <>
          <Menu.Button className="ml-5">
            <img
              src={open ? closeIc : burgerIc}
              alt="Toggle mobile menu"
              className="w-6"
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{ maxHeight: "calc(100vh - 68px)" }}
              className="w-full overflow-y-auto border-t border-ci-beige-50 absolute top-full right-0 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {menu.map((m) => {
                switch (m.component) {
                  case "submenu_link":
                    return <SLMob m={m} key={m["_uid"]} />;
                  case "menu_link":
                    return <MLMob m={m} key={m["_uid"]} />;
                  case "menu_img":
                    return (
                      <MIMob
                        m={m}
                        key={m["_uid"]}
                        prodotti={prodotti}
                        urls={urls}
                        translated={translatedProds[l] ?? {}}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const SLMob = ({ m }) => (
  <Link
    className="py-4 px-7 flexc justify-between bg-ci-beige-light border-b border-ci-beige-50"
    to={m.link}
  >
    {m.label}
    <img src={rigthIc} alt="" />
  </Link>
);

const MLMob = ({ m }) => (
  <Menu>
    {({ open }) => (
      <>
        <div className="py-4 px-7 flexc justify-between bg-ci-beige-light border-b border-ci-beige-50">
          <Link to={m.link}>{m.label}</Link>
          <Menu.Button>
            <img
              src={downIc}
              alt="Toggle sub menu"
              className={`transform transition-transform ${
                open ? "rotate-180" : ""
              }`}
            />
          </Menu.Button>
        </div>
        {open && (
          <Menu.Items as="div">
            {m.submenu.map((sm) => (
              <Link
                className="py-3 pr-7 pl-9 justify-between flexc bg-ci-white-light border-b border-ci-beige-50"
                to={sm.link}
                key={sm["_uid"]}
              >
                {sm.label}
                <img src={rigthIc} alt="" />
              </Link>
            ))}
          </Menu.Items>
        )}
      </>
    )}
  </Menu>
);

const MIMob = ({ m, prodotti, urls, translated }) => {
  return (
    <Menu>
      {({ open }) => (
        <>
          <div className="py-4 px-7 flexc justify-between bg-ci-beige-light border-b border-ci-beige-50">
            <Link to={m.link}>{m.label}</Link>
            <Menu.Button>
              <img
                src={downIc}
                alt="Toggle sub menu"
                className={`transform transition-transform ${
                  open ? "rotate-180" : ""
                }`}
              />
            </Menu.Button>
          </div>
          {open && (
            <Menu.Items
              as="div"
              className="bg-white py-5 px-7 flex flex-wrap gap-y-7 gap-x-4"
            >
              {m.submenu.map((sm) => {
                let { label, link, img, hov } = getSubmenuAttr(
                  sm,
                  prodotti,
                  urls
                );
                if (sm.component === "submenu_sku") {
                  label = getTranslatedIfAvail(
                    label,
                    translated[sm.sku.toLowerCase()]?.title,
                    true
                  );
                }
                return (
                  <Link
                    style={{ width: "calc(50% - 8px)" }}
                    className="flex-shrink-0 inline-block relative z-30"
                    to={link}
                    key={sm["_uid"]}
                  >
                    {img}
                    <p className="mt-3">{label}</p>
                  </Link>
                );
              })}
              {m.banner_link && (
                <Link
                  className="mt-6 rounded-ci-banner-mob relative overflow-hidden"
                  to={m.banner_link}
                >
                  <img
                    src={m.banner_img.filename}
                    alt={m.banner_img.alt ?? ""}
                  />
                  <div
                    className="absolute w-full h-full top-0 left-0"
                    style={{
                      background:
                        "linear-gradient(202.83deg, rgba(39,39,39,0) 0%, #000000 100%)",
                    }}
                  />
                  <div className="absolute bottom-5 left-5 w-10/12">
                    <p className="butler text-lg font-bold text-ci-white leading-none">
                      {m.banner_text}
                    </p>
                    <div className="inline-block uppercase bg-ci-red text-ci-white font-bold text-sm py-3 px-6 mt-4 rounded-full">
                      {m.banner_cta}
                    </div>
                  </div>
                </Link>
              )}
            </Menu.Items>
          )}
        </>
      )}
    </Menu>
  );
};

export default MenuMob;
