import React, { useState, useContext } from "react";
import emailIcon from "../../resources/images/icon_envelop_white.svg";
import phoneIcon from "../../resources/images/icon_phone_white.svg";
import helpIcon from "../../resources/images/icon_assistenza_white.svg";
import { Link } from "gatsby";
import { Transition } from "@headlessui/react";
import { LanguageContext } from "../../context/language-context";
import { useLocation } from "@reach/router";
import useBasePagesSEO from "../use-base-pages-seo";
import { useUrls } from "../utils";

const Topbar = () => {
  const [showLanguages, setShowLanguages] = useState(false);
  const [to, setTo] = useState(null);
  const { t, locale } = useContext(LanguageContext);
  const l = locale;
  const location = useLocation();
  const { pageInfo, urls2pag } = useBasePagesSEO();
  const urls = useUrls();
  const page = urls2pag[location.pathname];

  const getPageLinkInDifferentLanguage = (locale) => {
    if (page && pageInfo[page] && pageInfo[page][locale]) {
      return pageInfo[page][locale].url;
    }
    const [prodUrl] = Object.entries(urls[l])
      .filter(([_, url]) => location.pathname === url)
      .map(([sku, _]) => urls[locale][sku]);
    if (prodUrl) {
      return prodUrl;
    }
    return `/${locale}`;
  };

  return (
    <div className="bg-ci-black relative" style={{ zIndex: 45 }}>
      <div className="max-w-screen-xl lg:mx-auto px-4 flexc justify-center lg:justify-between">
        <p className="text-sm text-ci-white py-2.5">
          {/* Codice sconto 20% <b>SUMMER21</b> */}
        </p>
        <nav className="hidden lg:block">
          <ul className="flexc gap-4 text-white lg:h-10 text-sm">
            <li>
              <Link
                to={pageInfo["Assistenza Clienti"][l].url}
                className="flexc font-bold"
              >
                <img src={helpIcon} alt="" />
                {pageInfo["Assistenza Clienti"][l].title_seo}
              </Link>
            </li>
            <li>
              <a
                href="https://m.me/calchitaliani"
                target="_blank"
                rel="noopener noreferrer"
                className="flexc"
              >
                <img src={phoneIcon} alt="" />
                +39 02 808 886 83
              </a>
            </li>
            <li>
              <a href="mailto:ciao@calchitaliani.com" className="flexc">
                <img src={emailIcon} alt="" />
                ciao@calchitaliani.com
              </a>
            </li>
            <li
              className="h-7 leading-7 border-l pl-4 relative cursor-pointer"
              onMouseEnter={() => {
                if (to !== null) clearTimeout(to);
                setShowLanguages(true);
              }}
              onMouseLeave={() => {
                setTo(
                  setTimeout(() => {
                    setShowLanguages(false);
                  }, 100)
                );
              }}
            >
              {l.toUpperCase()}
              <Transition
                show={showLanguages}
                enter="transition-all transition duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-all duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className="absolute p-3 bg-ci-black z-50"
                  style={{ width: "160px", left: "-64px", top: "130%" }}
                >
                  <Link
                    to={l === "it" ? "#" : getPageLinkInDifferentLanguage("it")}
                    className="flexc gap-2.5"
                  >
                    IT - Italiano
                  </Link>
                  <Link
                    to={l === "en" ? "#" : getPageLinkInDifferentLanguage("en")}
                    className="flexc gap-2.5"
                  >
                    EN - English
                  </Link>
                </div>
              </Transition>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Topbar;
