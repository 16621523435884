import React, { useState, useContext } from "react";
import { StoreContext } from "../../context/store-context";
import Button from "../button";

const Regala = () => {
  const { setMessaggioAndRegalo, hasMessaggio, checkout } =
    useContext(StoreContext);
  let initMsg = "",
    initReg = false;
  checkout.customAttributes.forEach((ca) => {
    ca.key === "isRegalo" && ca.value === "true" && (initReg = true);
    ca.key === "messaggio" && (initMsg = ca.value);
  });
  const [isRegalo, setIsRegalo] = useState(initReg);
  const [currHasMessaggio, setHasMessaggio] = useState(hasMessaggio);
  const [messaggio, setMessaggioText] = useState(initMsg);
  return (
    <div>
      <div className="flex justify-between items-center gap-2 font-bold">
        <label className="flex gap-1.5 items-center text-sm">
          <input
            type="checkbox"
            defaultChecked={initReg}
            onChange={() => {
              setIsRegalo((old) => !old);
            }}
          />
          Questo ordine è un regalo
        </label>
        <p>€0.00</p>
      </div>
      <p className="mt-3 text-xs">
        Selezionando questa voce inseriremo all'interno della spedizione una
        ricevuta di cortesia, così la tua spesa resterà il nostro piccolo
        segreto ma il tuo destinatario potrà comunque contattarci per richiedere
        assistenza.
      </p>
      <div className="flex justify-between items-center gap-2 font-bold mt-6">
        <label className="flex gap-1.5 items-center text-sm">
          <input
            type="checkbox"
            defaultChecked={initMsg}
            onChange={() => {
              setHasMessaggio((old) => !old);
            }}
          />
          Aggiungi un messaggio con il cuore
        </label>
        <p>€2.00</p>
      </div>
      <textarea
        value={messaggio}
        className="py-4 px-3 border border-ci-gray resize-none mt-3 w-full text-xs"
        placeholder="Il tuo messaggio sarà stampato su carta speciale ed inserito in una busta all'interno della confezione."
        onChange={(e) => {
          setMessaggioText(e.target.value);
        }}
      />
      <p className="mt-3 text-xs">
        L'opzione regalo vale per l'intero ordine: se nel carrello hai più
        prodotti da regalare a persone diverse, ti suggeriamo di fare ordini
        separati.
      </p>
      <button
        className="mt-6 w-full"
        onClick={() => {
          setMessaggioAndRegalo(isRegalo, currHasMessaggio, messaggio);
        }}
      >
        <Button type="green" className="p-4 text-center w-full font-bold">
          Salva
        </Button>
      </button>
    </div>
  );
};

export default Regala;
