import React, { useState, useEffect, useContext } from "react";
import cartIcon from "../../resources/images/icon-cart.svg";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Transition } from "@headlessui/react";
import { StoreContext } from "../../context/store-context";
import {
  filterPromo,
  getSubmenuAttr,
  getTranslatedIfAvail,
  parseStory,
  useUrls,
} from "../utils";
import useWindowSize from "../use-window-size";
import MenuMob from "./menu-mob";
import { LanguageContext } from "../../context/language-context";

const heights = { menu_link: "84px", menu_img: "312px", submenu_link: "0px" };

const enterFrom = ["opacity-0", "opacity-0 -ml-40", "opacity-0 ml-40"];
const enterTo = ["opacity-100", "opacity-100 ml-0", "opacity-100 ml-0"];
const leaveFrom = ["opacity-100", "opacity-100 ml-0", "opacity-100 ml-0"];
const leaveTo = ["opacity-0", "opacity-0 ml-40", "opacity-0 -ml-40"];

const StickyHeader = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [[lineW, lineL], setHoverLine] = useState([0, 0]);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [lastActiveSubmenu, setLastActiveSubmenu] = useState(null);
  const [exitTimeout, setExitTimeout] = useState(null);
  const [entered, setEntered] = useState(false);
  const { isMobile } = useWindowSize(1024);
  const { t, locale } = useContext(LanguageContext);
  const l = locale;

  const urls = useUrls()[l];

  const data = useStaticQuery(graphql`
    query MenuQuery {
      menu: allStoryblokEntry(filter: { slug: { eq: "menu" } }) {
        nodes {
          lang
          content
        }
      }
      prodotti: allShopifyProduct {
        nodes {
          handle
          title
          images {
            src
            altText
          }
        }
      }
    }
  `);

  const menu =
    parseStory(
      data.menu.nodes.filter(
        (n) => (n.lang === "default" ? "it" : n.lang) === l
      )[0]
    )?.content?.elements ?? [];
  const prodotti = data.prodotti.nodes.reduce((obj, p) => {
    obj[p.handle.toLowerCase()] = p;
    return obj;
  }, {});

  useEffect(() => {
    const onScroll = (e) => {
      const sh = document.querySelector("#sticky-header");
      if (sh.offsetTop > 40 && isExpanded) {
        setIsExpanded(false);
      }
      if (sh.offsetTop <= 40 && !isExpanded) {
        setIsExpanded(true);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [isExpanded]);

  useEffect(() => {
    if (exitTimeout !== null && entered) {
      clearTimeout(exitTimeout);
    }
  }, [exitTimeout, entered]);

  const enterTarget = (e, elementIdx) => {
    setEntered(true);
    setHoverLine([e.currentTarget.offsetWidth, e.currentTarget.offsetLeft]);
    setActiveSubmenu(elementIdx);
  };

  const exitTarget = (e, elementIdx) => {
    setLastActiveSubmenu(elementIdx);
    setHoverLine([
      0,
      e.currentTarget.offsetLeft + e.currentTarget.offsetWidth / 2,
    ]);
    setEntered(false);
    setExitTimeout(
      setTimeout(() => {
        setLastActiveSubmenu(null);
        setActiveSubmenu(null);
      }, 300)
    );
  };

  const stickyBarHeight = isMobile ? "68px" : isExpanded ? "110px" : "66px";
  const animType =
    lastActiveSubmenu === null || activeSubmenu === lastActiveSubmenu
      ? 0
      : lastActiveSubmenu > activeSubmenu
      ? 1
      : 2;

  const storeCtx = useContext(StoreContext);

  return (
    <>
      <Transition
        show={
          activeSubmenu !== null &&
          menu[activeSubmenu].component !== "submenu_link"
        }
        enter="transition duration-300 delay-150"
        enterFrom="opacity-0"
        enterTo="opacity-50"
        entered="opacity-50"
        leave="transition duration-150"
        leaveFrom="opacity-50"
        leaveTo="opacity-0"
        as="div"
        className="fixed w-full h-full bg-black top-0 z-30"
      />
      <div
        id="sticky-header"
        className="sticky top-0 pointer-events-none z-40 shadow-md"
        style={{
          height: stickyBarHeight,
        }}
      >
        <header
          className="bg-white transition-all pointer-events-auto"
          style={{
            height: stickyBarHeight,
          }}
        >
          <div
            id="header-bar"
            className="max-w-screen-xl lg:mx-auto flexc justify-between h-full px-4"
          >
            <Link to={"/" + l} className="lg:hidden">
              <StaticImage
                alt="Calchi Italiani"
                src="../../resources/images/Logo_Complete_CalchiItaliani@3x.png"
                loading="eager"
                layout="fixed"
                height={44}
                placeholder="tracedSVG"
              />
            </Link>
            <Link to={"/" + l} className="hidden lg:block">
              {isExpanded ? (
                <StaticImage
                  alt="Calchi Italiani"
                  src="../../resources/images/Logo_Complete_CalchiItaliani@3x.png"
                  loading="eager"
                  layout="fixed"
                  height={70}
                  placeholder="tracedSVG"
                />
              ) : (
                <StaticImage
                  alt="Calchi Italiani"
                  src="../../resources/images/Logo_C_CalchiItaliani@3x.png"
                  loading="eager"
                  layout="fixed"
                  height={33}
                  placeholder="tracedSVG"
                />
              )}
            </Link>
            <nav className="items-center gap-2 cursor-pointer h-full flex">
              <ul className="hidden lg:flex h-full">
                {menu.map((element, idx) => (
                  <li
                    key={element.label}
                    className="main-menu px-4"
                    style={{ height: `calc(100% - 0px)` }}
                    onMouseEnter={(e) => {
                      enterTarget(e, idx);
                    }}
                    onMouseLeave={(e) => {
                      exitTarget(e, idx);
                    }}
                  >
                    <Link
                      to={element.link}
                      className="h-full flexc relative z-20"
                    >
                      {element.label}
                    </Link>
                    <div
                      className="bg-white absolute sub-menu w-full left-0 transition-all duration-300 delay-150"
                      style={{
                        height:
                          heights[
                            menu[activeSubmenu]?.component ?? "submenu_link"
                          ],
                      }}
                    />
                    {element.component !== "submenu_link" && (
                      <Transition
                        unmount={false}
                        show={activeSubmenu === idx}
                        enter="transition-all duration-300 delay-150"
                        enterFrom={enterFrom[animType]}
                        enterTo={enterTo[animType]}
                        leave="transition-all duration-150"
                        leaveFrom={leaveFrom[animType]}
                        leaveTo={leaveTo[animType]}
                        as="div"
                        className="absolute w-full border-t border-gray-300 sub-menu left-0 z-10"
                        style={{
                          top: "calc(100% - 1px)",
                          height: heights[element.component],
                        }}
                      >
                        {element.component === "menu_link" && (
                          <MenuLink menu={element} />
                        )}
                        {element.component === "menu_img" && (
                          <MenuImg
                            menu={element}
                            prodotti={prodotti}
                            urls={urls}
                            translated={storeCtx.translatedProds[l] ?? {}}
                          />
                        )}
                      </Transition>
                    )}
                  </li>
                ))}
                <div
                  id="hover-line"
                  className="absolute h-px bg-ci-black transition-all z-20"
                  style={{
                    top: `calc(${stickyBarHeight} - 1px)`,
                    left: `${lineL}px`,
                    width: `${lineW}px`,
                  }}
                />
              </ul>
              <a
                href="#cart"
                title="Apri il carrello"
                className="flexc gap-1"
                onClick={(e) => {
                  e.preventDefault();
                  storeCtx.setCart(true);
                }}
              >
                <img src={cartIcon} alt="carrello" />
                <div className="h-6 w-6 rounded-full bg-ci-beige text-white text-sm text-center leading-6">
                  {storeCtx.checkout.lineItems
                    .filter(filterPromo)
                    .reduce((cum, li) => cum + li.quantity, 0)}
                </div>
              </a>
              <MenuMob menu={menu} prodotti={prodotti} urls={urls} />
            </nav>
          </div>
        </header>
      </div>
    </>
  );
};

const MenuLink = ({ menu }) => (
  <div className="max-w-screen-xl lg:mx-auto flexc justify-center py-8 gap-8">
    {(menu?.submenu ?? []).map((submenu, idx) => (
      <Link to={submenu.link} className="font-bold" key={submenu["_uid"]}>
        {submenu.label}
      </Link>
    ))}
  </div>
);

const MenuImg = ({ menu, prodotti, urls, translated }) => (
  <div className="max-w-screen-xl lg:mx-auto flexc justify-center pb-12 pt-10 gap-8">
    {(menu?.submenu ?? []).map((submenu, idx) => {
      let { label, link, img, hov } = getSubmenuAttr(submenu, prodotti, urls);
      if (submenu.component === "submenu_sku") {
        label = getTranslatedIfAvail(
          label,
          translated[submenu.sku.toLowerCase()]?.title,
          true
        );
      }
      return (
        <Link to={link} key={submenu["_uid"]}>
          <div className="relative overflow-hidden" style={{ height: "197px" }}>
            <div className="absolute h-full left-0 top-0">{hov}</div>
            <div className="h-full transition-opacity hover:opacity-0 duration-300 relative z-10">
              {img}
            </div>
          </div>
          <p className="font-bold mt-4">{label}</p>
        </Link>
      );
    })}
  </div>
);

export default StickyHeader;
