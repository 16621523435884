import React, { useEffect, useState, useContext } from "react";
import { getTimeDiff, hasPassedLimitTime } from "../utils";
import { LanguageContext } from "../../context/language-context";

const SameDay = () => {
  // same day spedition
  const initTime = new Date();
  const [isSameDay, setIsSameDay] = useState(!hasPassedLimitTime(initTime));
  const [time, setTime] = useState({
    h: initTime.getHours(),
    m: initTime.getMinutes(),
    s: initTime.getSeconds(),
  });
  useEffect(() => {
    const d = new Date();
    if (!hasPassedLimitTime(d)) {
      const intval = setInterval(() => {
        const now = new Date();
        if (hasPassedLimitTime(now)) {
          setIsSameDay(false);
        }
        setTime(getTimeDiff(now));
      }, 500);
      return () => {
        clearInterval(intval);
      };
    }
  }, []);

  const { t, locale } = useContext(LanguageContext);
  const l = locale;

  return (
    isSameDay && (
      <p className="mt-2">
        {t["ordina-entro-1"]}{" "}
        <span className="font-bold">
          {time.h}h, {time.m}min {t.e} {time.s}sec
        </span>
        <br />
        {t["ordina-entro-2"]}
      </p>
    )
  );
};

export default SameDay;
